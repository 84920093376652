/* eslint-disable @typescript-eslint/explicit-function-return-type */
// base
import React, { Suspense, lazy, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { useStoreRehydrated } from 'easy-peasy';
import { Redirect, Route, Switch } from 'react-router-dom';

// project
import { Path } from 'app/const/Path';
import Container from '@material-ui/core/Container';
import { PrivateRoute } from 'app/utils/PrivateRoute';
import { RouteWithAppBar } from 'app/utils/RouteWithAppBar';
import { PageLoader } from 'app/modules/common/page-loader';
import { NoMatchPage } from 'app/modules/common/no-match-page';
import { ComponentDebugModule } from 'app/modules/component-debug-module';
import { Appbar as WebShopAppbar } from 'app/modules/web-shop/components/appbar';
import {
  AppState,
  initialAppState,
  LocalStorageKey,
} from 'app/state/data/CountryState';

// hooks
import { useGA } from 'app/hooks/useGA';
import { useGTag } from 'app/hooks/useGTag';
import { useUrlFilters } from 'app/hooks/useUrlFilters';
import { useViewsCount } from 'app/hooks/useViewsCount';
import { useScrollToTop } from 'app/hooks/useScrollToTop';
import { useInitialLoad } from 'app/hooks/useInitialLoad';
import { V1Redirections } from 'app/utils/v1Redirections';
import { useLocationBlocker } from 'app/hooks/useLocationBlocker';
import { useIntercom } from 'app/hooks/useIntercom';

// modules
const FaqModule = lazy(() => import('app/modules/faq-module'));
const AboutModule = lazy(() => import('app/modules/about-module'));
const DetailModule = lazy(() => import('app/modules/detail-module'));
const ExploreModule = lazy(() => import('app/modules/explore-module'));
const ContactModule = lazy(() => import('app/modules/contact-module'));
const SearchResultsModule = lazy(
  () => import('app/modules/search-results-module')
);
const UserSettingsModule = lazy(
  () => import('app/modules/user-management/user-settings-module')
);
const DataManagerModule = lazy(() => import('app/modules/data-manager-module'));
const ActivityDetailModule = lazy(
  () => import('app/modules/activity-detail-module')
);
const AuthCallbackModule = lazy(
  () => import('app/modules/auth-callback-module')
);
const GoogleAuthCallbackModule = lazy(
  () => import('app/modules/google-auth-callback-module')
);
const FileValidationReportModule = lazy(
  () => import('app/modules/file-validation-report')
);
const FileValidationXMLReportModule = lazy(
  () => import('app/modules/file-validation-xml-report')
);
const BookmarksModule = lazy(() => import('app/modules/bookmarks-module'));
const GenerateSitemapModule = lazy(() => import('app/modules/sitemap-module'));

// Webshop modules
const WebshopLandingPage = lazy(
  () => import('app/modules/web-shop/sub-modules/landing-module')
);
const MissionVision = lazy(
  () => import('app/modules/web-shop/sub-modules/mission-vision-module')
);
const Support = lazy(() => import('app/modules/web-shop/sub-modules/support'));

const KnowledgeHub = lazy(
  () => import('app/modules/web-shop/sub-modules/knowledge-hub-module')
);
const ServicesModule = lazy(
  () => import('app/modules/web-shop/sub-modules/services-module')
);
const PricingModule = lazy(
  () => import('app/modules/web-shop/sub-modules/pricing-module')
);
const OnboardingModule = lazy(
  () => import('app/modules/web-shop/sub-modules/onboarding')
);
const PaymentModule = lazy(
  () => import('app/modules/web-shop/sub-modules/payment')
);

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export function ModuleRoutes() {
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      localStorage.setItem('user_sub', JSON.stringify(user.sub));
    }
  }, [user]);
  useIntercom();
  useGA();
  useGTag();
  useUrlFilters();
  useViewsCount();
  useScrollToTop();
  useInitialLoad();
  useLocationBlocker();
  const appState = useRecoilValue<AppState>(initialAppState);

  useEffect((): void => {
    window.localStorage.setItem(
      LocalStorageKey.APP_STATE,
      JSON.stringify(appState)
    );
  }, [appState]);

  const isRehydrated = useStoreRehydrated();

  if (!isRehydrated) {
    return <PageLoader />;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/callback">
          <AuthCallbackModule />
        </Route>
        <Route exact path={Path.other.sitemap}>
          <GenerateSitemapModule />
        </Route>

        <Route exact path="/google-auth-redirect">
          <GoogleAuthCallbackModule />
        </Route>

        <Route path={Path.basePath}>
          <Container id="app-container" maxWidth="lg">
            <Switch>
              <RouteWithAppBar exact path={Path.home}>
                <ExploreModule />
              </RouteWithAppBar>

              <RouteWithAppBar exact path={Path.search}>
                <SearchResultsModule />
              </RouteWithAppBar>

              <RouteWithAppBar exact path={Path.explore}>
                <ExploreModule />
              </RouteWithAppBar>

              <RouteWithAppBar path={Path.detail.country}>
                <DetailModule type="country" />
              </RouteWithAppBar>

              <RouteWithAppBar path={Path.detail.donor}>
                <DetailModule type="donor" />
              </RouteWithAppBar>

              <RouteWithAppBar path={Path.detail.publisher}>
                <DetailModule type="publisher" />
              </RouteWithAppBar>

              <RouteWithAppBar path={Path.detail.organisation}>
                <DetailModule type="organisation" />
              </RouteWithAppBar>

              <PrivateRoute path={Path.dataManagement.root}>
                <DataManagerModule />
              </PrivateRoute>

              <PrivateRoute path={Path.dataManagement.validationReport}>
                <FileValidationReportModule />
              </PrivateRoute>

              <PrivateRoute path={Path.dataManagement.validationXmlReport}>
                <FileValidationXMLReportModule />
              </PrivateRoute>

              <RouteWithAppBar exact path={Path.general.about}>
                <AboutModule />
              </RouteWithAppBar>

              <RouteWithAppBar exact path={Path.general.faq}>
                <FaqModule />
              </RouteWithAppBar>
              <RouteWithAppBar exact path={Path.general.contact}>
                <ContactModule />
              </RouteWithAppBar>
              <RouteWithAppBar exact path={Path.bookmarks}>
                <BookmarksModule />
              </RouteWithAppBar>
              {/* -------------------------------------------- */}
              {/* user settings */}
              <PrivateRoute exact path={Path.userManagement.userSettingsRoute}>
                <UserSettingsModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={Path.userManagement.userSettingsRouteWithoutId}
                render={() => {
                  if (user) {
                    return (
                      <Redirect
                        to={Path.userManagement.userSettingsRoute.replace(
                          ':id',
                          user.sub as string
                        )}
                      />
                    );
                  }
                  return null;
                }}
              >
                <div />
              </PrivateRoute>
              <RouteWithAppBar path={Path.detail.activity}>
                <ActivityDetailModule />
              </RouteWithAppBar>
              <RouteWithAppBar exact path={Path.debug.components}>
                <ComponentDebugModule />
              </RouteWithAppBar>
              <RouteWithAppBar exact forceHideAppBarFilter>
                <NoMatchPage />
              </RouteWithAppBar>
            </Switch>
          </Container>
        </Route>

        {/* Webshop Routes */}
        <Route path={Path.webshopBasePath}>
          <WebShopAppbar />
          <Switch>
            <Route exact path={Path.webshop.home}>
              <WebshopLandingPage />
            </Route>
            <Route exact path={Path.webshop.about}>
              <MissionVision />
            </Route>
            <Route exact path={Path.webshop.support}>
              <Support />
            </Route>
            <Route exact path={Path.webshop.knowledgeHub}>
              <KnowledgeHub />
            </Route>
            <Route exact path={Path.webshop.services}>
              <ServicesModule />
            </Route>
            <Route exact path={Path.webshop.plans}>
              <PricingModule />
            </Route>
            <Route exact path={Path.webshop.onboarding}>
              <OnboardingModule />
            </Route>
            <Route exact path={Path.webshop.payment}>
              <PaymentModule />
            </Route>
            <V1Redirections />
            <Route exact>
              <NoMatchPage />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </Suspense>
  );
}
