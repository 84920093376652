import React from 'react';
import Intercom, { boot } from '@intercom/messenger-js-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import cryptoJs from 'crypto-js';

export function useIntercom(): void {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      const payload = {
        userID: user.sub,
      };
      getAccessTokenSilently().then((token: string) => {
        axios
          .post(
            `${process.env.REACT_APP_AUTH_API}/generate-intercom-hash`,
            {
              payload: cryptoJs.AES.encrypt(
                JSON.stringify(payload),
                process.env.REACT_APP_ENCRYPTION_SECRET as string
              ).toString(),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.hash) {
              Intercom({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
                user_id: user.sub,
                name: user.name,
                email: user.email,
                created_at: user.created_at,
                user_hash: response.data.hash,
              });
            } else {
              console.error(response.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    } else {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
      });
    }
  }, [user, isAuthenticated]);
}
