/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import get from 'lodash/get';
import { Path } from 'app/const/Path';
import { Link } from 'react-router-dom';
import { User } from 'app/interfaces/User';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { styles } from 'app/components/Drawer/common/ProfileCard/styles';
import { GeneralButton } from 'app/modules/explore-module/common/CountryPopupButton';
import { shutdown } from '@intercom/messenger-js-sdk';

interface ProfileCardProps {
  userFromStore: User | null; // Not all needed variables can be obtained from the auth0 user.
  user: User;
  logout: Function;
  orgName: string | null;
  clearUserAction: Function;
}

function formatDate(ISODateString: string | null): string {
  if (ISODateString) {
    const date = new Date(ISODateString);
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
    return `Joined ${month} ${date.getFullYear()}`;
  }
  return '';
}

export function ProfileCard(props: ProfileCardProps) {
  const date = formatDate(get(props.userFromStore, 'created_at', null));
  return (
    <div>
      <Avatar alt="icon" src={props.user.picture} css={styles.img} />
      <Typography variant="body1" css={styles.title}>
        {props.user.name}
      </Typography>
      {props.orgName && (
        <div
          css={`
            display: flex;
            margin-bottom: 6px;
            align-items: center;
          `}
        >
          <BusinessIcon fontSize="small" htmlColor="#A1AEBD" />
          <Typography css={styles.subTitle}>{props.orgName}</Typography>
        </div>
      )}
      {date && (
        <div
          css={`
            display: flex;
            align-items: center;
            margin-bottom: 12px;
          `}
        >
          <DateRangeIcon fontSize="small" htmlColor="#A1AEBD" />
          <Typography css={styles.subTitle}>{date}</Typography>
        </div>
      )}
      <Link
        replace
        to={`${Path.userManagement.userSetting}${props.user.sub}`}
        css={`
          > div {
            margin: 24px 0;

            > div {
              font-weight: 600;
            }
          }
        `}
      >
        <GeneralButton
          label="My Account"
          aria-label="Go to my account settings"
        />
      </Link>
      <a
        role="button"
        css={styles.secondaryButton}
        onClick={() => {
          shutdown();
          props.clearUserAction();
          props.logout({
            returnTo: window.location.origin,
          });
        }}
      >
        <ExitToAppIcon />
        Log out
      </a>
    </div>
  );
}
